import React from 'react'
import PropTypes from 'prop-types'

const Input = ({ wrapperClassName, label, labelClassName, ...rest }) => (
  <div className={wrapperClassName}>
    <label htmlFor={rest.name} className={labelClassName}>
      {label}
    </label>
    <input
      id={rest.name}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  </div>
)

Input.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
}

Input.defaultProps = {
  className:
    'appearance-none border border-gray-600 rounded w-full py-2 px-4 text-gray-800 leading-6 focus:outline-none focus:shadow-outline',
  labelClassName: 'block text-gray-900 text-sm font-semibold mb-2',
  wrapperClassName: 'mb-4',
}

export default Input
