import React from 'react'
import UserPlaceholderOne from 'assets/images/placeholder-user-1.png'
import UserPlaceholderTwo from 'assets/images/placeholder-user-2.png'
import UserPlaceholderThree from 'assets/images/placeholder-user-3.png'
import UserPlaceholderFour from 'assets/images/placeholder-user-4.png'
import imgISO from 'assets/images/img-iso.jpg'
import imgTeam from 'assets/images/img-aegis-team.jpg'
import imgPartnersOne from 'assets/images/img-hackers.jpg'
import illustrationHero from 'assets/images/illustration-team.svg'
import ReadyContainer from 'modules/common/components/ReadyContainer'

function CompanyContainer() {
  return (
    <>
      <section className="about-hero-section bg-primary-100">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-center flex-wrap">
            <div className="w-5/6 md:w-3/6 text-center order-1 md:order-1 px-4 md:px-0 mb-4 md:mb-6">
              <img className="mx-auto" src={illustrationHero} alt="Our Team" />
            </div>
            <div className="w-full md:w-3/6 px-4 md:px-10 order-2 md:order-2">
              <small className="text-sm text-black mb-2 uppercase leading-6">
                Our Company
              </small>
              <h4 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-6 text-primary-500">
                We are AegisOne
                <br className="hidden md:inline-block" /> Cyberdefense
                Corporation
              </h4>
              <p className="text-black text-base md:text-xl leading-6 inline-block">
                Secure First, Then Connect
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="about-more-section bg-white">
        <div className="container mx-auto pt-10 pb-64 md:py-16">
          <div className="flex items-start flex-wrap">
            <div className="w-full md:w-3/6 px-4 md:px-8">
              <h3 className="text-2xl md:text-3xl font-semibold mb-2 md:mb-6 text-primary-500">
                About AegisOne Cyberdefense Corporation
              </h3>
              <p className="text-base leading-6 text-black mb-6">
                AegisOne Cyberdefense Corporation, the sister company of
                Software Services and Integration Development (SSID), was
                established in 2018 by David Du Jr. with the mission to provide
                safe, secure, and client-oriented cyber security solutions to
                manage the growing risks in cyberspace today.
              </p>
              <p className="text-base leading-6 text-black mb-8">
                Though we’ve grown since our founding, we’re still the same
                agency at heart. We value your data like we value our company.
                Give us a call today and see what we can do for you.
              </p>
              <div className="flex items-center justify-start">
                <div className="pl-4 md:pl-16 w-full pr-4 md:pr-8 flex flex-col md:flex-row align-top flex-wrap justify-between mb-4 md:mb-6 text-center md:text-left">
                  <img
                    className="rounded-md w-32 md:w-40 h-32 md:h-40 mx-auto md:mx-0"
                    src={imgISO}
                    alt="ISO Certified"
                  />
                  <div className="mt-4 md:mt-0 ml-0 md:ml-6 flex-1">
                    <h3 className="text-base font-semibold mb-2 text-primary-500">
                      ISO Certified
                    </h3>
                    <p className="text-base leading-6 md:leading-8 text-black">
                      AegisOne follows the international guidelines ISO 29149
                      Vulnerability Disclosure and ISO 30111 Vunerability
                      Handling Processes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start">
                <div className="pl-4 md:pl-16 w-full pr-4 md:pr-8 flex flex-col md:flex-row align-top flex-wrap justify-between mb-4 md:mb-6 text-center md:text-left">
                  <img
                    className="rounded-md w-32 md:w-40 h-32 md:h-40 mx-auto md:mx-0"
                    src={imgTeam}
                    alt="AegisOne Cybersec Team"
                  />
                  <div className="mt-4 md:mt-0 ml-0 md:ml-6 flex-1">
                    <h3 className="text-base font-semibold mb-2 text-primary-500">
                      AegisOne Cybersec Team
                    </h3>
                    <p className="text-base leading-6 md:leading-8 text-black">
                      AegisOne Cyber Security Team are experts and capable on
                      assesing your web, mobile, and network security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-3/6 px-4 md:px-8">
              <h3 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-6 text-primary-500">
                Our Mission
              </h3>
              <p className="text-base leading-6 text-black mb-4 md:mb-6">
                Our mission is to provide innovative solutions with the help of
                the best local and foreign White-Hat researchers to help
                organizations have a better security posture and prevent
                cyber-criminals from exploiting their vulnerable Assets.
              </p>
              <h3 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-6 text-primary-500">
                Our Vision
              </h3>
              <p className="text-base leading-6 text-black mb-4 md:mb-6">
                To be one of the most trusted organization in both local and
                international for providing solutions in information security.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="value-partner-section bg-primary-800">
        <div className="container mx-auto py-16 md:py-40">
          <div className="w-full md:w-3/6 px-4 md:px-8 py-8 mx-auto z-20 bg-primary-100 relative md:rounded-lg md:shadow-2xl">
            <small className="text-xs md:text-sm text-black mb-2 uppercase leading-6">
              Our Valued Partners
            </small>
            <h4 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-6 text-primary-500">
              Rapid7 Intrusion <br />
              Detection and Response
            </h4>
            <p className="text-base leading-6 text-black mb-4 md:mb-6">
              From the makers of Metasploit, Rapid 7 created an application that
              monitors for malicious activity and policy violations on your
              network.
            </p>
            <p className="text-base leading-6 text-black mb-4 md:mb-6">
              Rapid7’s InsightIDR is your security center for incident detection
              and response, authentication monitoring, and endpoint visibility.
              InsightIDR identifies unauthorized access from external and
              internal threats and highlights suspicious activity so you don’t
              have to weed through thousands of data streams.
            </p>

            <p className="text-base leading-6 text-black">
              To read more information,{' '}
              <a
                href="https://www.rapid7.com/products/insightidr/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-500 font-semibold"
              >
                click here
              </a>
              .
            </p>
          </div>
        </div>
        <img
          className="floating-image one"
          src={imgPartnersOne}
          alt="Our Partners"
        />
      </section>
      <section className="py-10 md:pt-20 md:pb-48 bg-gray-500">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center text-center mb-5 md:mb-10">
            <div className="w-full lg:w-8/12 px-4">
              <h3 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-6 text-primary-500">
                Meet Our Team
              </h3>
              <p className="text-base leading-6 text-black mb-4 md:mb-6">
                Our team is your team. When your mission is to be better, faster
                and smarter, you need the best people driving your vision
                forward. a group of passionate leaders dedicated to the success
                of AegisOne customers, partners, and employees.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap justify-center text-center">
            <div className="w-full lg:w-6/12 px-4">
              <p className="text-base leading-6 text-primary-500 mb-6 font-semibold uppercase">
                Management
              </p>
            </div>
          </div>
          <div className="flex flex-wrap justify-center mb-6">
            <div className="w-full md:w-4/12 lg:w-3/12 lg:mb-0 mb-6 md:mb-12 px-4">
              <div className="px-6">
                <img
                  alt="David Du Jr."
                  src={UserPlaceholderOne}
                  className="shadow-lg rounded-full max-w-full mx-auto"
                  style={{ maxWidth: '120px' }}
                />
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold">David Du Jr.</h5>
                  <p className="mt-1 text-xs text-gray-800 uppercase font-semibold">
                    CEO
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-4/12 lg:w-3/12 lg:mb-0 mb-6 md:mb-12 px-4">
              <div className="px-6">
                <img
                  alt="David Du Sr."
                  src={UserPlaceholderTwo}
                  className="shadow-lg rounded-full max-w-full mx-auto"
                  style={{ maxWidth: '120px' }}
                />
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold">David Du Sr.</h5>
                  <p className="mt-1 text-xs text-gray-800 uppercase font-semibold">
                    Chairman
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-4/12 lg:w-3/12 lg:mb-0 mb-6 md:mb-12 px-4">
              <div className="px-6">
                <img
                  alt="Ralph"
                  src={UserPlaceholderThree}
                  className="shadow-lg rounded-full max-w-full mx-auto"
                  style={{ maxWidth: '120px' }}
                />
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold">Ralph</h5>
                  <p className="mt-1 text-xs text-gray-800 uppercase font-semibold">
                    Marketing Head
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center text-center">
            <div className="w-full lg:w-6/12 px-4">
              <p className="text-base leading-6 text-primary-500 mb-6 font-semibold uppercase">
                Development Team
              </p>
            </div>
          </div>
          <div className="flex flex-wrap justify-center mb-6">
            <div className="w-full md:w-4/12 lg:w-3/12 lg:mb-0 mb-6 md:mb-12 px-4">
              <div className="px-6">
                <img
                  alt="Kimberly"
                  src={UserPlaceholderFour}
                  className="shadow-lg rounded-full max-w-full mx-auto"
                  style={{ maxWidth: '120px' }}
                />
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold">Kimberly</h5>
                  <p className="mt-1 text-xs text-gray-800 uppercase font-semibold">
                    Back-End Developer
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-4/12 lg:w-3/12 lg:mb-0 mb-6 md:mb-12 px-4">
              <div className="px-6">
                <img
                  alt="Anthony"
                  src={UserPlaceholderOne}
                  className="shadow-lg rounded-full max-w-full mx-auto"
                  style={{ maxWidth: '120px' }}
                />
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold">Anthony</h5>
                  <p className="mt-1 text-xs text-gray-800 uppercase font-semibold">
                    Front-End Developer
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center text-center">
            <div className="w-full lg:w-6/12 px-4">
              <p className="text-base leading-6 text-primary-500 mb-6 font-semibold uppercase">
                Security Team
              </p>
            </div>
          </div>
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-4/12 lg:w-3/12 lg:mb-0 mb-6 md:mb-12 px-4">
              <div className="px-6">
                <img
                  alt="Charles"
                  src={UserPlaceholderTwo}
                  className="shadow-lg rounded-full max-w-full mx-auto"
                  style={{ maxWidth: '120px' }}
                />
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold">Charles</h5>
                  <p className="mt-1 text-xs text-gray-800 uppercase font-semibold">
                    Lead Vulnerability Researcher
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-4/12 lg:w-3/12 lg:mb-0 mb-6 md:mb-12 px-4">
              <div className="px-6">
                <img
                  alt="Evan"
                  src={UserPlaceholderThree}
                  className="shadow-lg rounded-full max-w-full mx-auto"
                  style={{ maxWidth: '120px' }}
                />
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold">Evan</h5>
                  <p className="mt-1 text-xs text-gray-800 uppercase font-semibold">
                    Vulnerability Researcher
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ReadyContainer />
    </>
  )
}

export default CompanyContainer
