/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

const Img = ({ defaultSrc, src, alt, ...rest }) => {
  const onError = e => {
    if (e.target.src !== defaultSrc) {
      e.target.src = defaultSrc
    }
  }

  return <img src={src || defaultSrc} alt={alt} onError={onError} {...rest} />
}

Img.propTypes = {
  defaultSrc: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default Img
