import { req } from 'react-reqq'
import { toast } from 'react-toastify'
import _ from 'lodash'

const statusCodes = {
  422: response => {
    const message = _.get(response, 'errors.0.message', '')
    toast.error(message)
  },
  400: response => {
    const message = _.get(response, 'message', '')
    toast.error(message)
  },
}

class Api {
  post = async args => {
    await req.post({
      ...args,
      onError: res => {
        const onError = _.get(args, 'onError', () => {})
        onError(res.response)
        this.errorMessage(res)
      },
    })
  }

  get = async args => {
    await req.get({
      ...args,
      onError: this.errorMessage,
    })
  }

  errorMessage = ({ response, status }) => {
    if (statusCodes[status]) {
      statusCodes[status](response)
    }
  }
}

export default new Api()
