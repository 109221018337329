import React from 'react'
import illustrationPricing from 'assets/images/illustration-pricing.svg'
import ReadyContainer from 'modules/common/components/ReadyContainer'
import { IconArrowWhite, IconCheckCircle } from '../common/components/Assets'

function PricingContainer() {
  return (
    <>
      <section className="pricing-section bg-primary-100">
        <div className="container mx-auto pt-10 md:pt-20 pb-20 md:pb-40">
          <div className="flex items-center justify-center flex-wrap">
            <div className="w-full md:w-3/6 px-4 md:px-8 mx-auto text-center mb-8 md:mb-16 relative">
              <img
                className="h-40 md:h-96 w-auto mx-auto mb-8 mb:mb-10"
                src={illustrationPricing}
                alt="Presenting our pricing"
              />
              <small className="text-sm text-black mb-2 uppercase leading-6">
                Choose your plan
              </small>
              <h3 className="text-2xl md:text-3xl font-semibold mb-6 text-primary-500">
                Our Simple Pricing Plans
              </h3>
              <p className="text-base leading-6 text-black">
                We value your budget that’s why we provide you a comprehensive
                pricing plan for our two services. Web Application Penetration
                Testing and Mobile Application Penetration Testing.
              </p>
            </div>
          </div>
          <div className="flex items-start justify-center flex-wrap">
            <div className="w-full md:w-1/2 lg:w-1/4 px-4 md:px-2 mb-6 md:mb-4 lg:mb-auto">
              <div className="w-full bg-white rounded shadow-md">
                <div className="py-6 lg:py-6 px-4 lg:px-6 bg-gray-200 h-auto md:h-32 lg:h-40">
                  <h4 className="font-medium text-xl md:text-2xl leading-6 md:leading-8 text-black">
                    Startup Package
                  </h4>
                  <h2 className="text-2xl md:text-3xl text-primary-500 font-bold leading-8 md:leading-10">
                    $495
                    {/* <span className="text-base md:text-lg align-bottom font-regular text-gray-700 ml-1">
                    /mo
                  </span> */}
                  </h2>
                </div>

                <ul className="border-t border-gray-600 border-solid py-4 md:py-6 px-4 md:px-6 text-left h-auto md:h-smallprice lg:h-pricing">
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      3 days VAPT
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Black Box Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      1 Web Application (without subdomain) or 1 Mobile
                      Application (IOS/Android)
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Static and Dynamic Security Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Automated and Manual Testing
                    </p>
                  </li>

                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Top 10 OWASP Web or Mobile Vulnerabilities
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Zero false-positives guaranteed
                    </p>
                  </li>
                </ul>
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/register/organization`}
                  className="bg-primary-500 w-full p-4 md:p-6 text-center rounded-b rounded-tr-none rounded-tl-none text-white font-semibold text-base leading-6 flex items-center justify-center hover:bg-primary-600"
                >
                  <span className="mr-2 md:mr-4">Sign Up Now</span>{' '}
                  <IconArrowWhite />
                </a>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 px-4 md:px-2 mb-6 md:mb-4 lg:mb-auto">
              <div className="w-full bg-white rounded shadow-md">
                <div className="py-6 lg:py-6 px-4 lg:px-6 bg-gray-200 h-auto md:h-32 lg:h-40">
                  <h4 className="font-medium text-xl md:text-2xl leading-6 md:leading-8 text-black">
                    Small / Medium Business Package
                  </h4>
                  <h2 className="text-2xl md:text-3xl text-primary-500 font-bold leading-8 md:leading-10">
                    $950
                    {/* <span className="text-base md:text-lg align-bottom font-regular text-gray-700 ml-1">
                    /mo
                  </span> */}
                  </h2>
                </div>

                <ul className="border-t border-gray-600 border-solid py-4 md:py-6 px-4 md:px-6 text-left h-auto md:h-smallprice lg:h-pricing">
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      6 days VAPT
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Black Box or Gray Box Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      1 Web Application (2 to 3 subdomain included) or 1 Mobile
                      Application (IOS/Android)
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Static and Dynamic Security Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Automated and Manual Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Top 10 OWASP Web or Mobile Vulnerabilities
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Zero false-positives guaranteed
                    </p>
                  </li>
                </ul>
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/register/organization`}
                  className="bg-primary-500 w-full p-4 md:p-6 text-center rounded-b rounded-tr-none rounded-tl-none text-white font-semibold text-base leading-6 flex items-center justify-center hover:bg-primary-600"
                >
                  <span className="mr-2 md:mr-4">Sign Up Now</span>{' '}
                  <IconArrowWhite />
                </a>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 px-4 md:px-2 mb-6 md:mb-4 lg:mb-auto">
              <div className="w-full bg-white rounded shadow-md">
                <div className="py-6 lg:py-6 px-4 lg:px-6 bg-gray-200 h-auto md:h-32 lg:h-40">
                  <h4 className="font-medium text-xl md:text-2xl leading-6 md:leading-8 text-black">
                    Enterprise Package
                  </h4>
                  <h2 className="text-2xl md:text-3xl text-primary-500 font-bold leading-8 md:leading-10">
                    $1,850
                    {/* <span className="text-base md:text-lg align-bottom font-regular text-gray-700 ml-1">
                    /mo
                  </span> */}
                  </h2>
                </div>

                <ul className="border-t border-gray-600 border-solid py-4 md:py-6 px-4 md:px-6 text-left h-auto md:h-smallprice lg:h-pricing">
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      14 days VAPT
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Black Box, Gray Box or White Box Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      1 Web Application or 1 Mobile Application (IOS/Android)
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Unlimited Subdomains
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Static and Dynamic Security Testing
                    </p>
                  </li>

                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Web Application Firewall bypass
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Application Logic Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Automated and Manual Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Top 10 OWASP Web or Mobile Vulnerabilities
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Zero false-positives guaranteed
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      CWE, CVE and CVSS compliance
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Including Re-Testing to verify the fixes in Web or Mobile
                      Application
                    </p>
                  </li>
                </ul>
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/register/organization`}
                  className="bg-primary-500 w-full p-4 md:p-6 text-center rounded-b rounded-tr-none rounded-tl-none text-white font-semibold text-base leading-6 flex items-center justify-center hover:bg-primary-600"
                >
                  <span className="mr-2 md:mr-4">Sign Up Now</span>{' '}
                  <IconArrowWhite />
                </a>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 px-4 md:px-2 mb-6 md:mb-4 lg:mb-auto">
              <div className="w-full bg-white rounded shadow-md">
                <div className="py-6 lg:py-6 px-4 lg:px-6 bg-gray-200 h-auto md:h-32 lg:h-40">
                  <h4 className="font-medium text-xl md:text-2xl leading-6 md:leading-8 text-black">
                    Enterprise Pro Package
                  </h4>
                  <h2 className="text-2xl md:text-3xl text-primary-500 font-bold leading-8 md:leading-10">
                    $3,850
                    {/* <span className="text-base md:text-lg align-bottom font-regular text-gray-700 ml-1">
                    /mo
                  </span> */}
                  </h2>
                </div>

                <ul className="border-t border-gray-600 border-solid py-4 md:py-6 px-4 md:px-6 text-left h-auto md:h-smallprice lg:h-pricing">
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      20 days VAPT
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Black Box, Gray Box or White Box Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      1 Web Application or 1 Mobile Application (IOS/Android)
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Unlimited Subdomains
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Static and Dynamic Security Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Web Application Firewall bypass
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Application Logic Testing
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Automated and Manual Testing
                    </p>
                  </li>

                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Top 10 OWASP Web or Mobile Vulnerabilities
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Zero false-positives guaranteed
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      CWE, CVE and CVSS compliance
                    </p>
                  </li>
                  <li className="flex items-start justify-between mb-2 md:mb-4">
                    <div className="py-1 pr-1">
                      <IconCheckCircle />
                    </div>
                    <p className="pl-1 md:pl-2 text-black leading-6 text-sm flex-grow">
                      Including Re-Testing to verify the fixes in Web or Mobile
                      Application
                    </p>
                  </li>
                </ul>
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/register/organization`}
                  className="bg-primary-500 w-full p-4 md:p-6 text-center rounded-b rounded-tr-none rounded-tl-none text-white font-semibold text-base leading-6 flex items-center justify-center hover:bg-primary-600"
                >
                  <span className="mr-2 md:mr-4">Sign Up Now</span>{' '}
                  <IconArrowWhite />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReadyContainer />
    </>
  )
}

export default PricingContainer
