import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { configureApi } from 'react-reqq'
import { Router } from 'react-router-dom'
import ScrollToTop from 'modules/common/components/ScrollToTop'
import history from './history'

import 'react-toastify/dist/ReactToastify.css'
import './assets/styles/internal.css'
import './assets/styles/main.css'

import App from './App'
import * as serviceWorker from './serviceWorker'

const store = configureApi({
  endpoint: process.env.REACT_APP_ENDPOINT,
})

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router history={history}>
        <ScrollToTop />
        <App />
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
