import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import ContactsContainer from './modules/contacts/ContactsContainer'
import CustomersContainer from './modules/customers/CustomersContainer'
import PricingContainer from './modules/pricing/PricingContainer'
import ServicesContainer from './modules/services/ServicesContainer'
import SolutionsContainer from './modules/solutions/SolutionsContainer'
import Footer from './modules/common/components/Footer'
import Header from './modules/common/components/Header/index'
import CompanyContainer from './modules/company/CompanyContainer'
import HomeContainer from './modules/home/HomeContainer'
import TermsContainer from './modules/home/TermsContainer'
import PrivacyContainer from './modules/home/PrivacyContainer'

function App() {
  return (
    <div className="website-wrapper">
      <Header />
      <div className="content-wrapper">
        <Switch>
          <Route exact path="/" component={HomeContainer} />
          <Route path="/company" component={CompanyContainer} />
          <Route path="/solutions" component={SolutionsContainer} />
          <Route path="/services" component={ServicesContainer} />
          <Route path="/pricing" component={PricingContainer} />
          <Route path="/customers" component={CustomersContainer} />
          <Route path="/contact" component={ContactsContainer} />
          <Route path="/terms" component={TermsContainer} />
          <Route path="/privacy" component={PrivacyContainer} />
          <Redirect to="/" />
        </Switch>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default App
