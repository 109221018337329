import React from 'react'
import { Link } from 'react-router-dom'

function ReadyContainer() {
  return (
    <>
      <section className="ready-section bg-white">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-center justify-start">
            <div className="w-full md:w-4/6 mx-auto px-4 md:px-8 text-center">
              <h3 className="text-xl md:text-3xl font-semibold mb-6 md:mb-8 text-primary-500">
                Ready to Improve Your Web & Mobile Application <br /> Security
                to the next level?
              </h3>
              <p className="text-base md:text-xl leading-7 mb:leading-8 text-black mb-4 md:mb-6">
                Get a quote now and see how cybersecurity testing <br />
                can improve your security with us.
              </p>
              <Link
                to="/pricing"
                className="btn btn-outline-primary text-xl py-4 px-8 block md:inline-block uppercase hover:bg-primary-500 hover:text-white font-semibold"
              >
                Get a Quote
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ReadyContainer
