/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

import ReadyContainer from 'modules/common/components/ReadyContainer'
import ContactForm from './ContactForm'
import SocialMedia from './SocialMedia'
import SideText from './SideText'
import Header from './Header'

function ContactsContainer() {
  return (
    <>
      <section className="contact-section bg-primary-100">
        <div className="container mx-auto pt-10 md:pt-20 pb-20 md:pb-40">
          <Header />
          <div className="flex items-start justify-center flex-wrap">
            <div className="w-full md:w-2/5 px-4 md:px-8 mb-8 md:mb-16 relative">
              <SideText />
              <SocialMedia />
            </div>
            <ContactForm />
          </div>
        </div>
      </section>
      <ReadyContainer />
    </>
  )
}

export default ContactsContainer
