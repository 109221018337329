import React from 'react'

function PrivacyContainer() {
  return (
    <div className="my-10 w-3/6 mx-auto">
      <h1 className="text-3xl font-bold text-center text-primary-500 mb-10">
        PRIVACY POLICY
      </h1>
      <p className="text-base mb-4 text-gray-900">
        Please read our Privacy Policy carefully. In our Privacy Policy, we will
        explain the process we employ in the collection, management, safeguard
        and retention of information.
      </p>
      <p className="text-base mb-6 text-gray-900">
        AegisOne Cyber Defense Corporation (“AegisOne”) is fully committed to
        uphold the privacy of information related to the security reports,
        personal and company information. AegisOne and our “VAPT Platform”
        ensure transparency in managing collected information in accordance with
        existing Philippine laws, rules and regulations.
      </p>
      <h2 className="text-xl font-semibold mb-4 text-primary-500">SCOPE</h2>
      <p className="text-base mb-6 text-gray-900">
        Our Privacy Policy is applicable to the users of our VAPT Platform (
        <a
          className="text-primary-500 hover:text-primary-600 font-bold"
          href="https://aegisonesecure.com"
        >
          https://aegisonesecure.com
        </a>
        ) including researchers and companies alike. Our Privacy Policy is also
        applicable whether the said users are using mobile device, tablet
        device, computer/laptop, smart TV or any device in accessing our VAPT
        Platform. In order to experience the maximum services that our VAPT
        Platform offers, we may ask for certain personal and organization
        information.
      </p>
      <h2 className="text-xl font-semibold mb-4 text-primary-500">
        COLLECTION OF INFORMATION
      </h2>
      <ul className="list-disc pl-6">
        <li>
          <p className="text-base mb-4 text-gray-900">
            AegisOne VAPT Platform is in the business of cyber security. We
            partner with various companies in order to address their security
            needs
          </p>
        </li>
        <li>
          <p className="text-base mb-4 text-gray-900">
            In using AegisOne VAPT Platform, the user and organization is
            responsible in providing accurate, complete and updated information.
          </p>
        </li>
        <li>
          <p className="text-base mb-4 text-gray-900">
            The following information may be collected once consent is given to
          </p>
          <ul className="list-disc pl-6">
            <li>
              <p className="text-base mb-4 text-gray-900">
                Personal information including Name, Email Address, Contact
                Number, and Image.
              </p>
            </li>
            <li>
              <p className="text-base mb-4 text-gray-900">
                Organization information including Name, Description, Contact
                Number, Logo, Scope and Out of Scope Assets (Web Application or
                Mobile Application), Scope Assets Credentials, and Team Members.
              </p>
            </li>
            <li>
              <p className="text-base mb-4 text-gray-900">
                Other information imported from the use of third party
                applications. Including but not limited to, Credit card details,
                billing information and etc.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p className="text-base mb-4 text-gray-900">
            Information may be updated, corrected, deleted by accessing AegisOne
            VAPT Platform.
          </p>
        </li>
        <li>
          <p className="text-base mb-4 text-gray-900">
            Information is automatically collected when the user visits or use
            AegisOne VAPT Platform, such as:
          </p>
          <ul>
            <li>
              <p className="text-base mb-4 text-gray-900">
                Internet Protocol address and Internet Service Provider.
              </p>
            </li>
            <li>
              <p className="text-base mb-4 text-gray-900">
                Browser type and related device ID
              </p>
            </li>
            <li>
              <p className="text-base mb-4 text-gray-900">
                Operating System, Date and Time Stamp
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p className="text-base mb-4 text-gray-900">
            Automatic collection of data is consistent with AegisOne Cyber
            Defense Corporation business interest in the detection of errors,
            fraud, and/or unauthorized transactions.
          </p>
        </li>
        <li className="text-base mb-6 text-gray-900">
          <p className="text-base mb-4 text-gray-900">
            AegisOne collection of information is in accordance with the Data
            Privacy Act and its Implementing Rules and Regulations. In case of
            conflict between our Privacy Policy versus existing laws and
            regulations, the latter shall prevail.
          </p>
        </li>
      </ul>
      <h2 className="text-xl font-semibold mb-4 text-primary-500">
        MANAGEMENT OF INFORMATION
      </h2>
      <ul className="list-disc pl-6">
        <li>
          <p className="text-base mb-4 text-gray-900">
            We use the collected information for our business and legitimate
            purposes, such as:
          </p>
          <ul className="list-disc pl-6">
            <li>
              <p className="text-base mb-4 text-gray-900">
                Identity Verification
              </p>
            </li>
            <li>
              <p className="text-base mb-4 text-gray-900">
                Organization Verification
              </p>
            </li>
            <li>
              <p className="text-base mb-4 text-gray-900">
                Communication with our users
              </p>
            </li>
            <li>
              <p className="text-base mb-4 text-gray-900">
                Reporting of security reports based on the organizations target
                scope.
              </p>
            </li>
            <li>
              <p className="text-base mb-4 text-gray-900">
                Processing of payment to our services through third party
                application (e.g. Paymongo and Paypal).
              </p>
            </li>
            <li>
              <p className="text-base mb-4 text-gray-900">
                Abuse and Fraud prevention
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p className="text-base mb-4 text-gray-900">
            Once consent is provided, we may use the information gathered to
            communicate with our users for relevant services that we and our
            partner companies may offer. These communications may be in the form
            of email, text message, or other applicable mode of communications.
          </p>
        </li>
        <li className="text-base mb-6 text-gray-900">
          <p className="text-base mb-4 text-gray-900">
            The user and organization is responsible on the disclosure of
            information which does not pertain to the user. The user warrants
            that he/she/it is authorize to disclose such information.
          </p>
        </li>
      </ul>
      <h2 className="text-xl font-semibold mb-4 text-primary-500">
        SAFEGUARD OF INFORMATION
      </h2>
      <ul className="list-disc pl-6">
        <li>
          <p className="text-base mb-4 text-gray-900">
            AegisOne has competent professional, technical, administrative
            personnel which will ensure the protection of information provided.
            The information provided is stored on AegisOne’s secured database.
          </p>
        </li>
        <li>
          <p className="text-base mb-4 text-gray-900">
            The user is responsible as regards to the confidentiality of log-in
            credentials (email address and password).
          </p>
        </li>
        <li>
          <p className="text-base mb-4 text-gray-900">
            AegisOne ensures that the information collected, processed and
            stored in its database are kept secure from unauthorized and/or
            unlawful use, access, processing, sharing of information.
          </p>
        </li>
        <li className="text-base mb-6 text-gray-900">
          <p className="text-base mb-4 text-gray-900">
            Despite the safeguards in place, we cannot guarantee the complete
            elimination of security and privacy risks in connection with the use
            of information based on our Privacy Policy.
          </p>
        </li>
      </ul>
      <h2 className="text-xl font-semibold mb-4 text-primary-500">
        RETENTION AND DELETION OF INFORMATION
      </h2>
      <ul className="list-disc pl-6">
        <li>
          <p className="text-base mb-4 text-gray-900">
            AegisOne retains the information provided for our record, and
            security reports for as long as the account of the user remains
            active, or AegisOne VAPT Platform remain active. Subject to
            applicable laws and regulations.
          </p>
        </li>
        <li>
          <p className="text-base mb-4 text-gray-900">
            The user can access information provided and security reports using
            our AegisOne VAPT Platform anytime.
          </p>
        </li>
        <li>
          <p className="text-base mb-4 text-gray-900">
            Correction, updating, deletion of personal information may be denied
            if the same may violate or prejudice another person or entities
            rights and/or otherwise permitted by relevant laws and regulations.
          </p>
        </li>
      </ul>
    </div>
  )
}

export default PrivacyContainer
