import React from 'react'

import illustrationContacts from 'assets/images/illustration-contacts.svg'

const Header = () => (
  <div className="flex items-center justify-center flex-wrap">
    <div className="w-full md:w-5/6 px-4 md:px-8 mx-auto text-center mb-8 md:mb-16 relative">
      <img
        className="h-40 md:h-96 w-auto mx-auto mb-8 md:mb-10"
        src={illustrationContacts}
        alt="Contact Us"
      />
      <small className="text-sm text-black mb-2 uppercase leading-6">
        Contact Us
      </small>
      <h3 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-6 text-primary-500">
        Get in touch with Us
      </h3>
    </div>
  </div>
)

export default Header
