import React from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from 'react-rte'

const getInitValue = str => {
  const markup = str || '<p></p>'
  const text = RichTextEditor.createValueFromString(markup, 'html')
  return text
}

const textToString = str => {
  try {
    return str.toString('html')
  } catch (error) {
    return '<p></p>'
  }
}

const RichText = ({ value, onChange, className, placeholder }) => {
  return (
    <RichTextEditor
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      toolbarConfig={{
        display: [],
      }}
    />
  )
}

RichText.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
}

RichText.defaultProps = {
  className:
    'rte form-textarea mt-1 block w-full border border-solid border-gray-600 rounded-md',
  placeholder: 'Enter the message here',
}

RichText.getInitValue = getInitValue
RichText.textToString = textToString

export default RichText
