import React from 'react'
import { Link } from 'react-router-dom'
import illustrationMobileHero from 'assets/images/illustration-mobile-hero.svg'
import illustrationMobileWorks from 'assets/images/illustration-mobile-other.svg'
import { IconArrowWhite } from 'modules/common/components/Assets'

function MobileContainer() {
  return (
    <>
      <section className="services-hero-section bg-primary-100">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-center flex-wrap">
            <div className="w-5/6 md:w-3/6 text-center order-1 md:order-1 px-4 md:px-0 mb-4 md:mb-auto">
              <img
                className="h-40 md:h-auto w-auto md:mx-auto"
                src={illustrationMobileHero}
                alt="Mobile Application Penetration Testing"
              />
            </div>
            <div className="w-full md:w-3/6 order-2 md:order-1 px-4 md:px-10">
              <small className="text-sm text-black mb-2 uppercase leading-6">
                Our Services
              </small>
              <h4 className="text-xl md:text-2xl text-primary-500 font-semibold mb-4 leading-snug">
                Mobile Application
                <br /> Penetration Testing
              </h4>
              <p className="text-black text-base md:text-xl leading-6 inline-block">
                Mobile application penetration testing discovers malicious or
                potential risk in your mobile applications, keeping your
                business and customers secure against attacks.
              </p>
              <div className="mt-4 md:mt-6">
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/register/organization`}
                  className="btn btn-primary py-4 inline-flex items-center"
                >
                  Get Started <IconArrowWhite />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-works-section bg-white">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-center flex-wrap">
            <div className="w-full md:w-3/6 order-2 md:order-1 px-4 md:px-10">
              <h4 className="text-2xl md:text-2xl md:text-3xl text-primary-500 font-semibold mb-4 leading-snug">
                Overview
              </h4>
              <p className="text-black text-base md:text-xl font-normal leading-6 md:leading-7 mb-4 md:mb-6">
                Mobile application security and privacy is a concern for
                enterprises big or small. No-one wants their customer’s valuable
                information ending up in the wrong hands because of a faulty
                line of code or a vulnerable third party component.
              </p>
              <p className="text-black text-base md:text-xl font-normal leading-6 md:leading-7 mb-4 md:mb-6">
                One way to avoid this risk is to make sure that mobile
                application have been properly tested against security
                vulnerabilities. A mobile application penetration testing
                simulates an attack specifically targeting organization’s custom
                mobile application (iOS or Android) and aims to enumerate all
                vulnerabilities within an application. For instance, insecure
                data storage, insecure authentication, insecure communication
                and to more traditional application-based issues such as
                username enumeration or injections.
              </p>
              <p className="text-black text-base md:text-xl font-normal leading-6 md:leading-7 mb-4 md:mb-8">
                By conducting mobile application penetration testing, the
                company can gain knowledge of vulnerabilities, potential risk,
                and attack vectors in the mobile application, before delivering
                an app to the user. As a result, the company can change the
                design, code, and configuration before release
              </p>

              <h4 className="text-2xl md:text-2xl md:text-3xl text-primary-500 font-semibold mb-4 leading-snug">
                What do you gain by undergoing mobile application penetration
                testing
              </h4>
              <ul className="pl-6 md:pl-8 list-disc">
                <li>
                  <p className="text-black text-base md:text-xl leading-6 md:leading-7 mb-2">
                    Identify the threats in your mobile application environment
                  </p>
                </li>
                <li>
                  <p className="text-black text-base md:text-xl leading-6 md:leading-7 mb-2">
                    Received a technical report outlining which vulnerabilities
                    in your mobile application environment are at risk along
                    with their main causes.
                  </p>
                </li>
                <li>
                  <p className="text-black text-base md:text-xl leading-6 md:leading-7 mb-2">
                    Remediation for solving discovered threats.
                  </p>
                </li>
                <li>
                  <p className="text-black text-base md:text-xl leading-6 md:leading-7 mb-2">
                    Publishing your mobile application without excess worry
                    about security risk.
                  </p>
                </li>
                <li>
                  <p className="text-black text-base md:text-xl leading-6 md:leading-7">
                    Avoid Fines and Comply with Industry Standards and
                    Regulations
                  </p>
                </li>
              </ul>
            </div>
            <div className="w-4/6 md:w-3/6 order-1 md:order-2 px-4 md:px-auto mb-4 md:mb-auto text-center">
              <img
                className="mx-auto"
                src={illustrationMobileWorks}
                alt="How it’s work"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="services-highlights-section bg-primary-200">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-stretch flex-wrap">
            <div className="w-full text-center px-4 md:px-auto mb-4">
              <h4 className="text-xl md:text-2xl text-primary-500 font-semibold">
                Methodology
              </h4>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 1. Scoping
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    Gather the clients target scope and identify the rules of
                    engagement regarding Mobile Application Penetration Testing.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 2. Information Gathering
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    Our Cyber Security Team will gather information on the given
                    target scope. E.g. check what services or libraries the
                    targets are using and other possible information we can get.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 3. Vulnerability Discovery
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    Based on the gathered information our Cyber Security Team
                    will try different techniques to identify the
                    vulnerabilities on the target scope.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 4. Exploitation
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    Our Cyber Security Team will exploit the vulnerability
                    discovered for proof of concept and eliminate false
                    positives.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 5. Reporting
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    After testing we will submit our technical and executive
                    report to your Organization.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 6. Remediation
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    Our Cyber Security Team will retest the reported
                    vulnerability if it’s fixed and what’s not. Then we will
                    submit our retesting report to your Organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap">
            <div className="w-full md:w-1/6 mx-auto px-4">
              <Link
                to="/pricing"
                className="bg-primary-500 w-full py-4 px-6 text-center inline-block rounded-md text-white font-semibold text-base leading-6 flex items-center justify-center"
              >
                <span>See Pricing</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MobileContainer
