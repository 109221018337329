import React from 'react'
import PropTypes from 'prop-types'
import Img from 'modules/common/components/Img'
import logoPlaceholder from 'assets/images/logo-placeholder.png'
import logoPinoyTravel from 'assets/images/pinoy-travel-logo.png'
import logoWildSoundStudio from 'assets/images/wildsound-logo.png'

const CustomerList = ({ data }) => (
  <div className="flex items-center justify-center content-center flex-wrap">
    <div className="w-full md:w-2/6 px-4 mb-4">
      <div className="relative rounded-lg block items-center bg-white shadow-xl">
        <div
          className="relative w-full h-full overflow-hidden rounded-lg"
          style={{ height: '12rem' }}
        >
          <div className="absolute inset-0 w-full h-full flex items-center flex-col justify-center">
            <Img
              className="w-4/6 h-20 object-contain object-center"
              src={logoPinoyTravel || ''}
              defaultSrc={logoPlaceholder}
              alt="PinoyTravel"
            />
            <p className="text-primary-500 mt-4 mb-0 font-bold">PinoyTravel</p>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-2/6 px-4 mb-4">
      <div className="relative rounded-lg block items-center bg-white shadow-xl">
        <div
          className="relative w-full h-full overflow-hidden rounded-lg"
          style={{ height: '12rem' }}
        >
          <div className="absolute inset-0 w-full h-full flex items-center flex-col justify-center">
            <Img
              className="w-4/6 h-20 object-contain object-center"
              src={logoWildSoundStudio || ''}
              defaultSrc={logoPlaceholder}
              alt="WILDSOUND STUDIOS"
            />
            <p className="text-primary-500 mt-4 mb-0 font-bold">
              WILDSOUND STUDIOS
            </p>
          </div>
        </div>
      </div>
    </div>
    {data.map(item => (
      <div key={item.name} className="w-full md:w-2/6 px-4 mb-4">
        <div className="relative rounded-lg block items-center bg-white shadow-xl">
          <div
            className="relative w-full h-full overflow-hidden rounded-lg"
            style={{ height: '12rem' }}
          >
            <div className="absolute inset-0 w-full h-full flex items-center flex-col justify-center">
              <Img
                className="w-4/6 h-20 object-contain object-center"
                src={item.image || ''}
                defaultSrc={logoPlaceholder}
                alt={item.name}
              />
              <p className="text-primary-500 mt-4 mb-0 font-bold">
                {item.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

CustomerList.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
}

export default CustomerList
