/* eslint-disable */
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'
import api from 'modules/helpers/api'
import RichText from 'modules/common/components/RichText'
import { form, formInitState } from './constants'

const ContactForm = () => {
  const [state, setState] = useState(formInitState)
  const recaptchaRef = React.createRef()

  const onChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value,
    })
  }

  const onSubmit = e => {
    e.preventDefault()
    api.post({
      key: 'POST_CONTACT_US',
      url: '/contact-us',
      payload: {
        ...state,
        message: RichText.textToString(state.message),
      },
      onSuccess: ({ response }) => {
        toast.success(response.message)
        setState(formInitState)
        // eslint-disable-next-line
        grecaptcha.reset(recaptchaRef)
      },
      onError: () => {
        // eslint-disable-next-line
        grecaptcha.reset(recaptchaRef)
      },
    })
  }

  return (
    <div className="w-full md:w-2/5 px-4 md:px-8 mb-auto md:mb-16 relative">
      <h3 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-6 text-primary-500">
        Talk to an expert
      </h3>
      <form onSubmit={onSubmit}>
        {form.map(({ component: El, ...rest }) => (
          <El
            key={rest.name}
            value={state[rest.name]}
            onChange={onChange}
            {...rest} // eslint-disable-line react/jsx-props-no-spreading
          />
        ))}
        <div className="mb-4">
          <label className="block text-gray-900 text-sm font-semibold mb-2">
            Message
          </label>
          <RichText
            value={state.message}
            onChange={newMessage => {
              setState({
                ...state,
                message: newMessage,
              })
            }}
          />
        </div>
        <div className="mb-4">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onChange={captcha =>
              setState({ ...state, 'g-recaptcha-response': captcha })
            }
          />
        </div>
        <button className="btn btn-primary px-8" type="submit">
          Send Message
        </button>
      </form>
    </div>
  )
}

export default ContactForm
