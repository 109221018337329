import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import api from 'modules/helpers/api'

import illustrationCustomers from 'assets/images/illustration-customers.svg'
import ReadyContainer from 'modules/common/components/ReadyContainer'
import CustomerList from './components/CustomerList'

function CustomersContainer() {
  const customerList = useSelector(
    state => state.api.customerListList || [],
    shallowEqual
  )

  useEffect(() => {
    api.get({
      key: 'customerListList',
      url: '/customers',
      transform: ({ data }) => data,
    })
  }, [])

  return (
    <>
      <section className="customer-section bg-primary-100">
        <div className="container mx-auto pt-10 mb:pt-20 pb-20 md:pb-40">
          <div className="flex items-center justify-center flex-wrap mb8">
            <div className="w-full md:w-3/6 px-4 md:px-8 mx-auto text-center mb-8 md:mb-16 relative">
              <img
                className="h-40 md:h-96 w-auto mx-auto mb-8 mb:mb-10"
                src={illustrationCustomers}
                alt="Customer Stories"
              />
              {/* <small className="text-sm text-black mb-2 uppercase leading-6">
                Customer Stories
              </small> */}
              <h3 className="text-2xl md:text-3xl font-semibold mb-6 text-primary-500">
                Trusted By
              </h3>
              {/* <p className="text-base leading-6 text-black">
                Join hundreds of companies who use AegisOne to protect their
                data and prevent breaches. We help their Website & Mobile
                Security
              </p> */}
            </div>
          </div>
          <CustomerList data={customerList} />
        </div>
      </section>
      <ReadyContainer />
    </>
  )
}

export default CustomersContainer
