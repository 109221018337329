import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const useOutside = (ref, callback = () => {}) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

const Outside = ({ children, wrapperElement: Wrapper, callback, ...rest }) => {
  const wrapperRef = useRef(null)
  useOutside(wrapperRef, callback)

  return (
    <Wrapper
      ref={wrapperRef}
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </Wrapper>
  )
}

Outside.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  wrapperElement: PropTypes.string,
  callback: PropTypes.func.isRequired,
}

Outside.defaultProps = {
  wrapperElement: 'div',
}

export default Outside
