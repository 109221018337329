import iconWeb from 'assets/images/icon-web.svg'
import iconMobile from 'assets/images/icon-mobile.svg'

export default [
  {
    key: 'home',
    exact: true,
    to: '/',
    label: 'Home',
  },
  {
    key: 'company',
    exact: false,
    to: '/company',
    label: 'Company',
  },
  {
    key: 'services',
    to: '/services',
    exact: false,
    label: 'Services',
    subNavs: [
      {
        key: 'services-web',
        icon: iconWeb,
        exact: false,
        to: '/services/web-app-pentest',
        label: 'Web Application Penetration Testing',
        subLabel: 'Identifying a security flaw in your web application',
      },
      {
        key: 'services-mobile',
        icon: iconMobile,
        exact: false,
        to: '/services/mobile-app-pentest',
        label: 'Mobile Application Penetration Testing',
        subLabel:
          'Discovers malicious or potential risk in your mobile applications',
      },
    ],
  },
  {
    key: 'solutions',
    exact: false,
    to: '/solutions',
    label: 'Solutions',
  },
  {
    key: 'customers',
    exact: false,
    to: '/customers',
    label: 'Customers',
  },
  {
    key: 'pricing',
    exact: false,
    to: '/pricing',
    label: 'Pricing',
  },
  {
    key: 'contact',
    exact: false,
    to: '/contact',
    label: 'Contact Us',
  },
]
