import React from 'react'

import { socialMediaList } from './constants'

const SocialMedia = () => (
  <>
    <h3 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-6 text-primary-500">
      Follow us on Social Media
    </h3>
    <ul className="px-4 md:px-8">
      {socialMediaList.map(item => (
        <li key={item.key}>
          <a
            className="flex items-center justify-start mb-4 md:mb-6"
            target="_blank"
            rel="noopener noreferrer"
            href={item.link}
          >
            <img
              src={item.img}
              alt={item.key}
              className="w-6 md:w-10 md:h-10"
            />
            <p className="ml-4 md:ml-6 text-base leading-6 md:leading-10 text-black">
              {item.text}
            </p>
          </a>
        </li>
      ))}
    </ul>
  </>
)

export default SocialMedia
