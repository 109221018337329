import React from 'react'
import PropTypes from 'prop-types'

const ButtonMenu = ({ onClick }) => (
  <button onClick={onClick} type="button" className="flex items-center">
    <svg
      className="fill-current text-gray h-6 w-6 hover:text-primary-500"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Menu</title>
      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
  </button>
)

ButtonMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ButtonMenu
