/* eslint-disable react/no-array-index-key */
import React from 'react'

import { sideTextList } from './constants'

const SideText = () => (
  <>
    <h3 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-6 text-primary-500">
      Let’s discuss your project
    </h3>
    {sideTextList.map((item, i) => (
      <p
        key={i}
        className="text-base md:text-xl leading-5 md:leading-7 text-black mb-4 md:mb-6 font-normal"
      >
        {item}
      </p>
    ))}
  </>
)

export default SideText
