import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'

import { MainLogo } from 'modules/common/components/Assets'
import ClickOutside from '../ClickOutside'
import ButtonMenu from './ButtonMenu'
import MenuLink from './MenuLink'

import navs from './navs'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const closeTab = () => {
    if (isOpen) {
      setIsOpen(false)
    }
  }

  return (
    <div className="main-header shadow-md">
      <div className="container flex items-center justify-between flex-wrap mx-auto px-4 md:px-auto">
        <div className="logo-brand">
          <Link to="/">
            <MainLogo />
          </Link>
        </div>
        <div className="block lg:hidden">
          <ButtonMenu onClick={() => setIsOpen(!isOpen)} />
        </div>
        <ClickOutside
          callback={closeTab}
          className={`menu-list ${isOpen ? 'show' : 'hide'}`}
          wrapperElement="div"
        >
          {navs.map(item => (
            <MenuLink
              closeWholeTab={closeTab}
              subNavs={item.subNavs || []}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...item}
            />
          ))}
        </ClickOutside>
        <div className={`menu-action ${isOpen ? 'show' : 'hide'}`}>
          <a
            href={`${process.env.REACT_APP_RESEARCHER_URL}/register/researcher`}
            className="block lg:inline-block px-3 btn btn-primary my-2 text-center md:mr-4"
          >
            Join as Researcher
          </a>
          <a
            href={`${process.env.REACT_APP_CLIENT_URL}/login`}
            className="block lg:inline-block px-3 btn btn-primary my-2 text-center"
          >
            Client Login
          </a>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Header)
