import React from 'react'
import iconFacebook from 'assets/images/icon-fb.png'
// import iconInstagram from 'assets/images/icon-instagram.png'
import iconTwitter from 'assets/images/icon-twitter.png'
import iconLinkedin from 'assets/images/icon-linkedin.png'
import { Link } from 'react-router-dom'
import { MainLogo } from './Assets'

function Footer() {
  return (
    <div className="main-footer pt-10 pb-4 lg:py-20">
      <div className="container block lg:flex justify-between flex-wrap mx-auto px-4 lg:px-auto mb-10">
        <div className="w-full lg:w-1/5 mr-auto">
          <MainLogo size="small" />
          <div className="mt-3">
            <a
              className="mb-2 text-base text-gray-700 block leading-6"
              href="mailto:help@aegisonecybersec.com"
            >
              help@aegisonecybersec.com
            </a>
            <a
              className="text-base text-primary-500 block leading-6"
              href="tel:+ 639989710737"
            >
              +63 998 971 0737
            </a>
          </div>
        </div>
        <div className="w-full lg:w-4/5 flex-1  block lg:flex justify-between pt-3">
          <div className="w-full lg:w-auto px-auto pt-4 lg:px-4">
            <p className="text-base font-semibold text-primary-500 leading-6 mb-6">
              About Us
            </p>
            <ul>
              <li>
                <Link className="text-sm leading-6" to="/company">
                  Our Company
                </Link>
              </li>
              <li>
                <Link className="text-sm leading-6" to="/solutions">
                  Solutions
                </Link>
              </li>
              <li>
                <Link className="text-sm leading-6" to="/pricing">
                  Pricing
                </Link>
              </li>
              <li>
                <Link className="text-sm leading-6" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-auto px-auto pt-4 lg:px-4">
            <p className="text-base font-semibold text-primary-500 leading-6 mb-6">
              Services & Customers
            </p>
            <ul>
              <li>
                <Link
                  className="text-sm leading-6"
                  to="/services/web-app-pentest"
                >
                  Web App Penetration Testing
                </Link>
              </li>
              <li>
                <Link
                  className="text-sm leading-6"
                  to="/services/mobile-app-pentest"
                >
                  Mobile App Penetration Testing
                </Link>
              </li>
              <li className="mt-6" />
              <li>
                <Link className="text-sm leading-6" to="/customers">
                  Our Customers
                </Link>
              </li>
            </ul>
          </div>

          <div className="w-full lg:w-auto px-auto pt-4 lg:px-4">
            <p className="text-base font-semibold text-primary-500 leading-6 mb-6">
              Join Now
            </p>
            <ul>
              <li>
                <a
                  href={`${process.env.REACT_APP_RESEARCHER_URL}/register/researcher`}
                  className="text-sm leading-6"
                >
                  Register as Researcher
                </a>
              </li>
              <li className="mt-6" />
              <li>
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/register/organization`}
                  className="text-sm leading-6"
                >
                  Register as Client
                </a>
              </li>
            </ul>
          </div>

          <div className="w-full lg:w-auto px-auto pt-4 lg:px-4">
            <p className="text-base font-semibold text-primary-500 leading-6 mb-6">
              Address
            </p>
            <ul>
              <li>
                <p className="text-sm leading-6">
                  Level 10-1 One Global Place, 5th Avenue & 25th Street
                  <br />
                  Bonifacio Global City, Taguig 1632 Philippines
                </p>
              </li>
              <li className="mt-4">
                <a
                  className="inline-block mr-2"
                  href="https://www.facebook.com/aegisonecybersec"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconFacebook} alt="Facebook" />
                </a>
                {/* <a
                  className="inline-block mr-2"
                  href="http://www.instagram.com/username"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconInstagram} alt="Instagram" />
                </a> */}
                <a
                  className="inline-block mr-2"
                  href="https://twitter.com/aegisonesecure"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconTwitter} alt="Twitter" />
                </a>

                <a
                  className="inline-block mr-2"
                  href="https://www.linkedin.com/company/aegisonesecure/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconLinkedin} alt="Linkedin" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container block lg:flex justify-between flex-wrap mx-auto border-t border-gray-600 border-solid py-2">
        <p className="w-full lg:w-auto text-xs text-center lg:text-left leading-6">
          &copy; 2020{' '}
          <Link to="/" className="text-primary-500 font-semibold">
            AegisOne Cyberdefense Corporation
          </Link>
          . All rights reserved.
        </p>
        <div className="w-full lg:w-auto ml-auto">
          <p className="text-xs leading-6 text-center lg:text-right">
            <Link to="/terms" className="text-primary-500 font-semibold">
              Terms of Use
            </Link>
            <span className="mx-4 text-primary-500 font-semibold">•</span>
            <Link to="/privacy" className="text-primary-500 font-semibold">
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
