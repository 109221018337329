import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, NavLink } from 'react-router-dom'

const SubMenu = ({ onClick, children, icon, subLabel, to }) => (
  <li className="submenu-item">
    <NavLink
      to={to}
      onClick={onClick}
      className="flex flex-column items-center justify-between px-4 py-2 hover:bg-primary-100"
    >
      <div className="w-16 h-16 mr-4">
        <img src={icon} alt={children} />
      </div>
      <div className="flex-1">
        <p className="text-base text-primary-500 leading-6 mb-1">{children}</p>
        <p className="text-sm text-gray-700">{subLabel}</p>
      </div>
    </NavLink>
  </li>
)

SubMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  subLabel: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default withRouter(SubMenu)
