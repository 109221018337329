import React from 'react'
import { Player, BigPlayButton, ControlBar } from 'video-react'
import illustrationRapid from 'assets/images/illustration-rapid.svg'
import illustrationRapidOther from 'assets/images/illustration-rapid-other.svg'
import videoPoster from 'assets/images/poster-image.jpg'
import videoMp4 from 'assets/images/insightidr-rapid.mp4'
import illustrationRapidAbout from 'assets/images/illustration-rapid-about.svg'
import InsigntIDRArchitecture from 'assets/images/insightidr-architecture.png'
import 'video-react/dist/video-react.css'
import ReadyContainer from 'modules/common/components/ReadyContainer'

function SolutionsContainer() {
  return (
    <>
      <section className="solution-hero-section bg-primary-100">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-center flex-wrap">
            <div className="w-5/6 md:w-3/6 text-center order-1 md:order-1 px-4 md:px-0 mb-4 md:mb-6 md:mb-0">
              <img
                className="mx-auto"
                src={illustrationRapid}
                alt="Our Solutions"
              />
            </div>
            <div className="w-full md:w-3/6 px-4 md:px-10 order-2 md:order-2">
              <small className="text-sm text-black mb-2 uppercase leading-6">
                Our Solution Partners
              </small>
              <h4 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-6 text-primary-500">
                Rapid7 Intrusion
                <br className="hidden md:inline-block" />
                Detection and Response
              </h4>
              <p className="text-black text-base md:text-xl leading-6 inline-block">
                Your security center for incident detection and response
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="solution-about-section bg-white">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-center justify-center flex-wrap">
            <div className="w-full md:w-3/5 order-2 md:order-1 px-4 md:px-8 relative">
              <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 text-primary-500">
                About Rapid7 Intrusion Detection and Response
              </h3>
              <h4 className="text-bae md:text-xl font-semibold mb-2 md:mb-4 text-primary-500">
                InsightIDR Overview
              </h4>
              <p className="text-base leading-6 text-black font-normal mb-2 md:mb-4">
                Rapid7’s InsightIDR is your security center for incident
                detection and response, authentication monitoring, and endpoint
                visibility. InsightIDR identifies unauthorized access from
                external and internal threats and highlights suspicious activity
                so you don’t have to weed through thousands of data streams.
              </p>
              <p className="text-base leading-6 text-black font-normal mb-2 md:mb-4">
                InsightIDR combines the full power of endpoint forensics, log
                search, and sophisticated dashboards into a single solution. It
                is a Software as a Service (SaaS) tool that collects data from
                your existing network security tools, authentication logs, and
                endpoint devices. InsightIDR then aggregates the data at an
                on-premises Collector or a dedicated host machine that
                centralizes your data.
              </p>
              <img
                className="mb-2 md:mb-4 w-full"
                src={InsigntIDRArchitecture}
                alt="InsigntIDR Architecture"
              />

              <p className="text-base leading-6 text-black font-normal mb-2 md:mb-4">
                Use this Collector to gather and transmit your logs securely to
                Amazon Web Services (AWS), which hosts customer databases and
                the web interface. Rapid7 runs analytics on this data to
                correlate users, accounts, authentications, alerts, and
                privileges. The analysis provides insight into user behavior
                while searching for known indicators of compromise.
              </p>

              <p className="text-base leading-6 text-black font-normal mb-2 md:mb-4">
                For more information{' '}
                <a
                  className="text-primary-500 font-semibold"
                  href="https://www.rapid7.com/products/insightidr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  click here
                </a>
              </p>
            </div>
            <div className="w-3/5 md:w-2/5 order-1 md:order-2 mb-4 md:mb-auto px-8 relative text-center">
              <img
                src={illustrationRapidAbout}
                alt="About Rapid7 Intrusion Detection and Response"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="solution-watch-section bg-primary-100">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-center justify-center flex-wrap">
            <div className="w-full md:w-3/5 px-4 md:px-8 relative">
              <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 text-primary-500 text-center">
                Watch Highlights and Videos
              </h3>
              <Player
                playsInline
                height={500}
                poster={videoPoster}
                src={videoMp4}
                className="mb-4 md:mb-6"
              >
                <BigPlayButton position="center" />
                <ControlBar disableDefaultControls />
              </Player>

              <p className="text-sm text-gray-700 mt-10 md:mt-20 mb-4 text-center px-4 md:px-8">
                Rapid7 recommends keeping dedicated Collectors on-premises to
                collect event data, log data, and endpoint data.
              </p>
              <img
                className="mx-auto px-10 md:px-20"
                src={illustrationRapidOther}
                alt="Our Solutions"
              />
            </div>
          </div>
        </div>
      </section>
      <ReadyContainer />
    </>
  )
}

export default SolutionsContainer
