import React from 'react'
import { Link } from 'react-router-dom'
import illustrationWebHero from 'assets/images/illustration-web-hero.svg'
import illustrationWebWorks from 'assets/images/illustration-web-other.svg'
import { IconArrowWhite } from 'modules/common/components/Assets'

function WebContainer() {
  return (
    <>
      <section className="services-hero-section bg-primary-100">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-center flex-wrap">
            <div className="w-full md:w-3/6 order-2 md:order-1 px-4 md:px-10">
              <small className="text-sm text-black mb-2 uppercase leading-6">
                Our Services
              </small>
              <h4 className="text-xl md:text-2xl text-primary-500 font-semibold mb-4 leading-snug">
                Web Application
                <br /> Penetration Testing
              </h4>
              <p className="text-black text-base md:text-xl leading-6 inline-block">
                Web Application Penetration Testing is a method of identifying a
                security flaw in your web application by simulating a
                cyber-attack against your organization to check for exploitable
                vulnerabilities.
              </p>
              <div className="mt-4 mb:mt-6">
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/register/organization`}
                  className="btn btn-primary py-4 inline-flex items-center"
                >
                  Get Started <IconArrowWhite />
                </a>
              </div>
            </div>
            <div className="w-5/6 md:w-3/6 text-center order-1 md:order-2 px-4 md:px-0 mb-4 md:mb-auto">
              <img
                className="h-40 md:h-auto w-auto md:mx-auto"
                src={illustrationWebHero}
                alt="Our Team"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="services-works-section bg-white">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-center flex-wrap">
            <div className="w-4/6 md:w-3/6 text-center px-4 md:px-auto">
              <img
                className="mx-auto"
                src={illustrationWebWorks}
                alt="Overview"
              />
            </div>
            <div className="w-full md:w-3/6 px-4 md:px-10">
              <h4 className="text-2xl md:text-2xl md:text-3xl text-primary-500 font-semibold mb-4 leading-snug">
                Overview
              </h4>
              <p className="text-black text-base md:text-xl font-normal leading-6 md:leading-7 mb-4 md:mb-6">
                Web application security issues or vulnerabilities is the number
                one concern when doing your business online. This is solely
                because websites are open to internet hence can potentially
                expose sensitive data which interests malicious persons,
                resulting to cyber-attacks. That’s the reason web application
                penetration testing services are so important for organizations.
                This is to ensure that your organization and client’s sensitive
                information are safe and protected from cyber-attacks.
              </p>
              <p className="text-black text-base md:text-xl font-normal leading-6 md:leading-7 mb-4 md:mb-8">
                Web application penetration testing works by using automated or
                manual penetration tests to identify any vulnerabilities,
                security flaws or threats in a web application. The tests
                involve using/implementing any of the known malicious
                penetration attacks on the application. It helps to identify
                potential weaknesses in various areas such as logical
                vulnerabilities,potential security exposures, security
                misconfigurations, poorly written development code, and
                lack-of-defensive measures.
              </p>

              <h4 className="text-2xl md:text-2xl md:text-3xl text-primary-500 font-semibold mb-4 leading-snug">
                Benefits
              </h4>
              <ul className="pl-6 md:pl-8 list-disc">
                <li>
                  <p className="text-black text-base md:text-xl leading-6 md:leading-7 mb-2">
                    Identify and resolved the vulnerability before the bad guys
                    abuse it or damage your systems.
                  </p>
                </li>
                <li>
                  <p className="text-black text-base md:text-xl leading-6 md:leading-7 mb-2">
                    Gain real-world insight into your vulnerabilities.
                  </p>
                </li>
                <li>
                  <p className="text-black text-base md:text-xl leading-6 md:leading-7 mb-2">
                    Protect customer data/information and company image.
                  </p>
                </li>
                <li>
                  <p className="text-black text-base md:text-xl leading-6 md:leading-7 mb-2">
                    Received a technical report outlining which vulnerabilities
                    in your web application environment are at risk along with
                    their main causes.
                  </p>
                </li>
                <li>
                  <p className="text-black text-base md:text-xl leading-6 md:leading-7 mb-2">
                    Remediation for solving discovered threats.
                  </p>
                </li>
                <li>
                  <p className="text-black text-xl leading-7">
                    Avoid Fines and Comply with Industry Standards and
                    Regulations
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="services-highlights-section bg-primary-200">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-stretch flex-wrap">
            <div className="w-full text-center px-4 md:px-auto mb-4">
              <h4 className="text-xl md:text-2xl text-primary-500 font-semibold">
                Methodology
              </h4>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 1. Scoping
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    Gather the clients target scope and identify the rules of
                    engagement regarding Web Application Penetration Testing.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 2. Information Gathering
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    Our Cyber Security Team will gather information on the given
                    target scope. E.g. learn how the site works,check what web
                    application services or libraries the targets are using and
                    other possible information we can get.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 3. Vulnerability Discovery
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    Based on the gathered information our Cyber Security Team
                    will try different techniques to identify the
                    vulnerabilities on the target scope.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 4. Exploitation
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    Our Cyber Security Team will exploit the vulnerability
                    discovered for proof of concept and eliminate false
                    positives.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 5. Reporting
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    After testing we will submit our technical and executive
                    report to your Organization.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4 flex items-center">
                <div>
                  <h4 className="text-base md:text-xl text-primary-500 leading-6 md:leading-7 mb-2 uppercase font-semibold">
                    Step 6. Remediation
                  </h4>
                  <p className="text-black text-base leading-6 md:leading-7">
                    Our Cyber Security Team will retest the reported
                    vulnerability if it’s fixed and what’s not. Then we will
                    submit our retesting report to your Organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-highlights-section bg-white">
        <div className="container mx-auto py-10 md:py-16">
          <div className="flex items-center justify-center flex-wrap mb-6 md:mb-10">
            <div className="w-full text-center">
              <h4 className="text-xl md:text-2xl text-primary-500 font-semibold mb-4">
                Web Application Common Vulnerabilities
              </h4>
            </div>
            <div className="w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4">
                <h4 className="text-2xl md:text-3xl text-primary-500 font-semibold mb-4">
                  Cross Site Scripting
                </h4>
                <p className="text-black text-base font-normal leading-6 md:leading-7 mb-4">
                  XSS flaws occur whenever an application includes untrusted
                  data in a new web page without proper validation or escaping,
                  or updates an existing web page with user-supplied data using
                  a browser API that can create HTML or JavaScript. XSS allows
                  attackers to execute scripts in the victim’s browser which can
                  hijack user sessions, deface web sites, or redirect the user
                  to malicious sites.
                </p>
              </div>
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4">
                <h4 className="text-2xl md:text-3xl text-primary-500 font-semibold mb-4">
                  SQL Injection
                </h4>
                <p className="text-black text-base font-normal leading-6 md:leading-7 mb-4">
                  A SQL injection attack consists of insertion or “injection” of
                  a SQL query via the input data from the client to the
                  application. A successful SQL injection exploit can read
                  sensitive data from the database, modify database data
                  (Insert/Update/Delete), execute administration operations on
                  the database (such as shutdown the DBMS), recover the content
                  of a given file present on the DBMS file system and in some
                  cases issue commands to the operating system. SQL injection
                  attacks are a type of injection attack, in which SQL commands
                  are injected into data-plane input in order to effect the
                  execution of predefined SQL commands.
                </p>
              </div>
            </div>
            <div className="w-full md:w-3/6 px-4">
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4">
                <h4 className="text-2xl md:text-3xl text-primary-500 font-semibold mb-4">
                  Broken Authentication
                </h4>
                <p className="text-black text-base font-normal leading-6 md:leading-7 mb-4">
                  Application functions related to authentication and session
                  management are often implemented incorrectly, allowing
                  attackers to compromise passwords, keys, or session tokens, or
                  to exploit other implementation flaws to assume other users’
                  identities temporarily or permanently.
                </p>
              </div>
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4">
                <h4 className="text-2xl md:text-3xl text-primary-500 font-semibold mb-4">
                  Broken Access Control
                </h4>
                <p className="text-black text-base font-normal leading-6 md:leading-7 mb-4">
                  Restrictions on what authenticated users are allowed to do are
                  often not properly enforced. Attackers can exploit these flaws
                  to access unauthorized functionality and/or data, such as
                  access other users’ accounts, view sensitive files, modify
                  other users’ data, change access rights, etc.
                </p>
              </div>
              <div className="bg-white shadow-md rounded-md p-6 md:p-8 mb-4">
                <h4 className="text-2xl md:text-3xl text-primary-500 font-semibold mb-4">
                  Security Misconfiguration
                </h4>
                <p className="text-black text-base font-normal leading-6 md:leading-7 mb-4">
                  Security misconfiguration is the most commonly seen issue.
                  This is commonly a result of insecure default configurations,
                  incomplete or ad hoc configurations, open cloud storage,
                  misconfigured HTTP headers, and verbose error messages
                  containing sensitive information. Not only must all operating
                  systems, frameworks, libraries, and applications be securely
                  configured, but they must be patched/upgraded in a timely
                  fashion.
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap">
            <div className="w-full md:w-1/6 mx-auto px-4">
              <Link
                to="/pricing"
                className="bg-primary-500 w-full py-4 px-6 text-center inline-block rounded-md text-white font-semibold text-base leading-6 flex items-center justify-center"
              >
                <span>See Pricing</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WebContainer
