import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import WebContainer from './WebContainer'
import MobileContainer from './MobileContainer'

function ServicesContainer({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}/web-app-pentest`} component={WebContainer} />
      <Route
        path={`${match.url}/mobile-app-pentest`}
        component={MobileContainer}
      />
      <Route exact path={`${match.url}/`} component={WebContainer} />
    </Switch>
  )
}

ServicesContainer.propTypes = {
  match: PropTypes.objectOf().isRequired,
}

export default ServicesContainer
