import React from 'react'

import Input from 'modules/common/components/Input'
import RichText from 'modules/common/components/RichText'
// import TextArea from 'modules/common/components/TextArea'

import iconFacebook from 'assets/images/icon-fb.png'
// import iconInstagram from 'assets/images/icon-instagram.png'
import iconTwitter from 'assets/images/icon-twitter.png'
import iconLinkedin from 'assets/images/icon-linkedin.png'

export const formInitState = {
  name: '',
  email: '',
  company: '',
  job_title: '',
  contact_number: '',
  message: RichText.getInitValue(),
}

export const form = [
  {
    label: 'Full Name',
    type: 'text',
    name: 'name',
    component: Input,
    placeholder: 'Your name',
  },
  {
    label: 'Work Email',
    type: 'email',
    name: 'email',
    component: Input,
    placeholder: 'Your work email address',
  },
  {
    label: 'Company',
    type: 'text',
    name: 'company',
    component: Input,
    placeholder: 'Your company',
  },
  {
    label: 'Job title',
    type: 'text',
    name: 'job_title',
    component: Input,
    placeholder: 'Your job title',
  },
  {
    label: 'Contact No.',
    type: 'tel',
    name: 'contact_number',
    component: Input,
    placeholder: '+63 XXX XXX XXXX',
  },
  // {
  //   label: 'Message',
  //   name: 'message',
  //   component: TextArea,
  //   placeholder: 'Your message',
  // },
]

export const socialMediaList = [
  {
    key: 'facebook',
    img: iconFacebook,
    link: 'https://www.facebook.com/aegisonecybersec',
    text: (
      <>
        facebook.com<strong>/aegisonecybersec</strong>
      </>
    ),
  },
  {
    key: 'twitter',
    img: iconTwitter,
    link: 'https://twitter.com/aegisonesecure',
    text: (
      <>
        twitter.com<strong>/aegisonesecure</strong>
      </>
    ),
  },
  // {
  //   key: 'instagram',
  //   img: iconInstagram,
  //   link: '',
  //   text: (
  //     <>
  //       instagram.com<strong>/aegisonecybersec</strong>
  //     </>
  //   ),
  // },
  {
    key: 'linkedin',
    img: iconLinkedin,
    link: 'https://www.linkedin.com/company/aegisonesecure/',
    text: (
      <>
        linkedin.com<strong>/company/aegisonesecure</strong>
      </>
    ),
  },
]

export const sideTextList = [
  'Let’s discuss your project',
  'If you’re looking to learn more about our services, or interested in working with AegisOne in your next project, our team will be happy to speak with you.',
  'Fill out the form and our team will reach out to you to discuss your project, answer your queries, or give you a introduction of our services.',
  'To learn more about our plans, visit our pricing page.',
  'We’re looking forward to work with you!',
]
