import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter, NavLink } from 'react-router-dom'

import { IconDropdown } from 'modules/common/components/Assets'
import ClickOutside from '../ClickOutside'
import SubMenu from './SubMenu'

const MenuSubLink = ({ to, exact, label, subNavs, closeWholeTab }) => {
  const [isOpen, setIsOpen] = useState(false)

  const closeTab = () => {
    if (isOpen) {
      setIsOpen(false)
      closeWholeTab()
    }
  }

  const onOpen = e => {
    e.preventDefault()
    setIsOpen(true)
  }

  if (!subNavs.length) {
    return (
      <div className="menu-item">
        <NavLink
          exact={exact}
          to={to}
          activeClassName="active"
          className="menu-link"
          onClick={closeWholeTab}
        >
          {label}
        </NavLink>
      </div>
    )
  }

  return (
    <ClickOutside
      callback={closeTab}
      className="menu-item"
      wrapperElement="div"
    >
      <NavLink
        to={to}
        activeClassName="active"
        className="menu-link"
        onClick={onOpen}
      >
        {label}
        <IconDropdown />
      </NavLink>
      {isOpen && (
        <div className="submenu-list">
          <ul>
            {subNavs.map(item => (
              <SubMenu
                onClick={closeTab}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...item}
              >
                {item.label}
              </SubMenu>
            ))}
          </ul>
        </div>
      )}
    </ClickOutside>
  )
}

MenuSubLink.propTypes = {
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  subNavs: PropTypes.instanceOf(Array).isRequired,
  closeWholeTab: PropTypes.func.isRequired,
}

export default withRouter(MenuSubLink)
