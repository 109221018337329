import React from 'react'

function TermsContainer() {
  return (
    <div className="my-10 w-3/6 mx-auto">
      <h1 className="text-3xl font-bold text-center text-primary-500 mb-10">
        TERMS OF USE
      </h1>
      <p className="text-base mb-4 text-gray-900">
        Please read our Terms of Use carefully. In our Terms of Use, we will
        explain the relationship between us and the user (organization and
        vulnerability researchers) of our AegisOne VAPT Platform, located at{' '}
        <a
          className="text-primary-500 hover:text-primary-600 font-bold"
          href="https://aegisonesecure.com"
        >
          https://aegisonesecure.com
        </a>
      </p>
      <p className="text-base mb-4 text-gray-900">
        Accessing our AegisOne VAPT Platform is entirely voluntary on the part
        of the user. Once consent is provided, the user accepts, without any
        reservation, this Terms of Use and our Privacy Policy. The user warrants
        careful reading and understanding of the provisions contained therein.
      </p>
      <p className="text-base mb-4 text-gray-900">
        AegisOne Cyber Defense Corporation (“AegisOne”) reserves the right to
        modify the contents of the Terms of Use at any given time. The continued
        use of AegisOne Platform after such modification constitutes
        acknowledgment of the modified Terms of Use.
      </p>
      <p className="text-base mb-6 text-gray-900">
        AegisOne reserves the right to ban users who will violate any provisions
        of this Terms of Use from accessing AegisOne VAPT Platform.
      </p>
      <ul className="list-roman pl-6">
        <li>
          <p className="text-base mb-6 text-gray-900 font-bold">REGISTRATION</p>
          <ul className="list-disc pl-6">
            <li>
              <p className="text-base mb-6 text-gray-900 font-bold">COMPANY</p>
              <ul className="list-disc pl-6">
                <li>
                  <p className="text-base mb-6 text-gray-900">
                    To register on AegisOne VAPT Platform, the user must be 18
                    years of age and above.
                  </p>
                </li>
                <li>
                  <p className="text-base mb-6 text-gray-900">
                    The user must ensure that the details provided on
                    registration or at any time are correct and complete.
                  </p>
                </li>
                <li>
                  <p className="text-base mb-6 text-gray-900">
                    The user grants AegisOne the right to disclose to third
                    parties certain registration information about the user. The
                    information obtained through the use of AegisOne VAPT
                    Platform is subject to AegisOne’s Privacy Policy, which is
                    specifically incorporated by reference into these Terms of
                    Use
                  </p>
                </li>
                <li>
                  <p className="text-base mb-6 text-gray-900">
                    Registration to use AegisOne VAPT Platform requires the
                    creation of email address and password. The confidentiality
                    of these log-in credentials shall be the responsibility of
                    the user.
                  </p>
                </li>
                <li>
                  <p className="text-base mb-6 text-gray-900">
                    Should AegisOne has reason to believe that there is likely
                    to be a breach of security or misuse of AegisOne VAPT
                    Platform, AegisOne may require the user to change the
                    password or AegisOne may suspend the user’s account.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p className="text-base mb-6 text-gray-900 font-bold">
                RESEARCHER
              </p>
              <ul className="list-disc pl-6">
                <li>
                  <p className="text-base mb-6 text-gray-900">
                    To join as researcher on AegisOne VAPT Platform, the user
                    must be 18 years of age and above.
                  </p>
                </li>
                <li>
                  <p className="text-base mb-6 text-gray-900">
                    The user must ensure that the details provided on
                    registration or at any time are correct and complete.
                  </p>
                </li>
                <li>
                  <p className="text-base mb-6 text-gray-900">
                    By submitting your request to join as researcher in AegisOne
                    VAPT Platform. You agree and allowing AegisOne Cyber Defense
                    Corporation to have a copy of your CV or Resume that
                    contains your personal information to assess if you’re
                    qualified to be part of AegisOne security team.
                  </p>
                </li>
                <li>
                  <p className="text-base mb-6 text-gray-900">
                    Accepting of applicants for AegisOne security team is solely
                    in the discretion of AegisOne management.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p className="text-base mb-6 text-gray-900 font-bold">ORGANIZATION</p>
          <ul className="list-disc pl-6">
            <li>
              <p className="text-base mb-6 text-gray-900">
                Organizations needs to have a valid account owner and company
                before the organization can subscribe to AegisOne VAPT services.
              </p>
            </li>
            <li>
              <p className="text-base mb-6 text-gray-900">
                Organizations needs to have a set of team who will facilitate
                the submitted reports from AegisOne security team. This people
                would be the contact person for every report submitted and it
                could be a security or development team.
              </p>
            </li>
            <li>
              <p className="text-base mb-6 text-gray-900">
                Organizations needs to have a valid service subscription before
                they can receive security reports from AegisOne security team.
              </p>
            </li>
            <li>
              <p className="text-base mb-6 text-gray-900">
                Availing the onetime 20% discount on the subtotal of the
                selected services will require the organization to accept the
                condition of allowing AegisOne to post their logo as one of our
                customers in AegisOne VAPT Platform customer’s page.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p className="text-base mb-6 text-gray-900 font-bold">
            ADMINISTRATOR
          </p>
          <ul className="list-disc pl-6">
            <li>
              <p className="text-base mb-6 text-gray-900">
                Administrator accounts has access to some organizations settings
                including:
              </p>
              <ul className="list-disc pl-6">
                <li>
                  <p className="text-base mb-6 text-gray-900">
                    Organization settings
                  </p>
                  <ul className="list-disc pl-6">
                    <li>
                      <p className="text-base mb-6 text-gray-900">
                        Organization Details
                      </p>
                    </li>
                    <li>
                      <p className="text-base mb-6 text-gray-900">
                        Rules of Engagement
                      </p>
                    </li>
                    <li>
                      <p className="text-base mb-6 text-gray-900">Scope</p>
                    </li>
                    <li>
                      <p className="text-base mb-6 text-gray-900">
                        Out of Scope
                      </p>
                    </li>
                  </ul>
                </li>

                <li>
                  <p className="text-base mb-6 text-gray-900">Team Settings</p>
                </li>
                <li>
                  <p className="text-base mb-6 text-gray-900">Invoice</p>
                </li>
                <li>
                  <p className="text-base mb-6 text-gray-900">Logs</p>
                </li>
              </ul>
              <p className="text-base mb-6 text-gray-900">
                Administrator accounts has the ability to update the mentioned
                organization settings but doesn’t do it without the
                organizations consent.
              </p>
            </li>
            <li>
              <p className="text-base mb-6 text-gray-900">
                Editing of the organization basic information including but not
                limited to scope, rules of engagement and etc. will require
                organizations request through{' '}
                <a
                  className="text-primary-500 hover:text-primary-600 font-bold"
                  href="mailto:help@aegisonecybersec.com"
                >
                  help@aegisonecybersec.com
                </a>
                .
              </p>
            </li>
            <li>
              <p className="text-base mb-6 text-gray-900">
                The AegisOne security team will handle the findings and
                submission of security reports to organizations through AegisOne
                VAPT Platform.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p className="text-base mb-6 text-gray-900 font-bold">SERVICES</p>
          <ul className="list-disc pl-6">
            <li>
              <p className="text-base mb-6 text-gray-900">
                The services offered in AegisOne VAPT Platform are Web
                Application Penetration Testing and Mobile Application
                Penetration Testing.
              </p>
            </li>
            <li>
              <p className="text-base mb-6 text-gray-900">
                The services offered in AegisOne VAPT Platform are in
                subscription basis. Once the service subscription expired, the
                organization will no longer be able to receive new security
                reports from AegisOne security team.
              </p>
            </li>
            <li>
              <p className="text-base mb-6 text-gray-900">
                In processing payments, we use third party service to process
                your payment (e.g. PayMongo and PayPal). The organization will
                handle the transaction fee of 3.5% + ₱15 or $0.31 for using
                PayMongo payment method.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p className="text-base mb-6 text-gray-900 font-bold">REPORTS</p>
          <ul className="list-disc pl-6">
            <li>
              <p className="text-base mb-6 text-gray-900">
                All reports that is reported through AegisOne VAPT Platform will
                stay in the platform. Until otherwise AegisOne VAPT Platform
                will cease to exist.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p className="text-base mb-6 text-gray-900 font-bold">
            GENERAL TERMS
          </p>
          <ul className="list-disc pl-6">
            <li>
              <p className="text-base mb-6 text-gray-900">
                The laws of the Philippines, excluding its conflicts of law
                rules, shall govern this Terms of Use and the user’s use of
                AegisOne VAPT Platform. The regulator or any competent court of
                the Philippines shall have jurisdiction in case of dispute. The
                use of AegisOne VAPT Platform may also be subject to other
                local, regional, national, or international laws.
              </p>
            </li>
            <li>
              <p className="text-base mb-6 text-gray-900">
                Should any part of these Terms be held invalid or unenforceable,
                that portion shall be construed as much as possible consistent
                with applicable law and severability shall apply to the
                remaining portions, so that they remain in full force and
                effect.
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default TermsContainer
